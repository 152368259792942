/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          dynamicTagCondition => method to define if the tag should be displayed
          dynamicTagLabel => method to display some text based on certain evaluations
          dynamicTagColor => method to apply on badge element based on certain evaluations
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: '/solicitante/inicio',
    name: 'Inicio',
    icon: 'HomeIcon',
  },
  // {
  //   url: '/solicitante/perfil',
  //   name: 'Mi Perfil',
  //   icon: 'UserIcon',
  // },
  // {
  //   url: '/solicitante/solicitud-de-credito',
  //   name: 'Solicitud de crédito',
  //   icon: 'BriefcaseIcon',
  // },
  // {
  //   url: '/solicitante/kyc',
  //   name: 'Queremos conocerte',
  //   icon: 'HelpCircleIcon',
  // },
  // {
  //   url: '/solicitante/referencias',
  //   name: 'Mis referencias',
  //   icon: 'UsersIcon',
  // },
  // {
  //   url: '/solicitante/credito',
  //   name: 'Mi crédito',
  //   icon: 'DollarSignIcon',
  // },
  {
    url: null,
    name: 'Mis créditos',
    //tag: 'new',
    //tagColor: 'primary',
    icon: 'StarIcon',
    submenu: [],
    slug:'creditsMainMenu',
    condition: 'show_applicant_menu_options'
  },
  {
    url: '/solicitante/realizar-pagos',
    name: 'Realizar pagos',
    icon: 'DollarSignIcon',
    condition: 'show_applicant_menu_options',
    slug: 'realizarPagosSolicitante',
  },
  {
    url: '/solicitante/movimientos',
    name: 'Mis movimientos',
    icon: 'ListIcon',
    condition: 'show_applicant_menu_options',
  },
  {
    url: '/solicitante/estados-cuenta',
    name: 'Estados de cuenta',
    slug: 'estadosCuentaSolicitante',
    icon: 'FileTextIcon',
    condition: 'show_applicant_menu_options',
  },
  // {
  //   header: 'Beneficiarios',
  //   icon: 'PackageIcon',
  //   items: [
  //     {
  //       url: '/solicitante/beneficiarios',
  //       name: 'Listado',
  //       slug: 'misBeneficiarios',
  //       icon: 'BriefcaseIcon',
  //     },
  //     {
  //       url: '/solicitante/beneficiarios/nuevo',
  //       name: 'Nuevo',
  //       slug: 'altaBeneficiario',
  //       icon: 'PlusCircleIcon',
  //     }
  //   ]
  // },
  // {
  //   header: 'Solicitudes',
  //   icon: 'PackageIcon',
  //   items: [
  //     {
  //       url: '/solicitante/mis-proyectos',
  //       name: 'Listado',
  //       slug: 'misProyectos',
  //       icon: 'BriefcaseIcon',
  //     },
  //     {
  //       url: '/solicitante/crear-proyecto/nuevo',
  //       name: 'Nuevo',
  //       slug: 'altaProyecyo',
  //       icon: 'PlusCircleIcon',
  //     }
  //   ]
  // }
]